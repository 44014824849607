import React from "react"
import { Helmet } from "react-helmet"
import "../components/layout.css"
import 'typeface-open-sans/index.css'
import Footer from "../components/footer.js"
import NavBar from "../components/navbar.js"
import StandardForm from "../components/standardform.js"
import config from "../config.yaml"


const PhotoFormTemplate = ({ pageContext }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Submit a photo</title>
    </Helmet>
    <NavBar navLinks={config.navLinks} />
    <StandardForm name="photo">
      <p>
        If you would like to add a photo which includes Ros you think would look good here, 
        please supply one here, in as high a resolution as you have available. 
        Dates can be approximate.
      </p>
      <p>
        <label htmlFor="submitter-input">Your name</label>
        <input id="submitter-input" type="text" name="submitter"/>
      </p>
      <p>
        <label htmlFor="photo-input">Photo </label>
        <input id="photo-input" type="file" name="photo"/> 
      </p>
      <p>
        <label htmlFor="date-input">Approximate date it was taken</label>
        <input id="date-input" type="date" name="date" />
      </p>
    </StandardForm>
    <Footer />
  </>
);


export default PhotoFormTemplate;